import Vue from 'vue'
import { ethers } from 'ethers';
import store from '../store'
import gb from '../assets/json/gb'
import ru from '../assets/json/ru'
import kr from '../assets/json/kr'
import cn from '../assets/json/cn'

const langs = {gb, ru, kr, cn}

Vue.filter('lang', key => {
    const lang = store.getters.lang
    return (langs[lang] || gb)[key]
})

Vue.filter('toGasFormat', val => {
  if(val && val > 0){
    let gasFees = ethers.utils.parseUnits(val+'',9);
    gasFees = ethers.utils.formatUnits(gasFees,18);
    return gasFees;
  }
  return val;
});

Vue.filter('toShowGas', val => {
  if(val[0] && val[1]){
    let transactionFees = ethers.utils.parseUnits(val[0]+'',18);
    
    let gasFees = ethers.utils.parseUnits(val[1]+'',9);
    // gasFees = ethers.utils.formatUnits(gasFees,18);

    let total = transactionFees.add(gasFees)
    total = ethers.utils.formatUnits(total,18);
    return total;
  }
  return val;
});

Vue.filter('to4dp', val => val && parseFloat(val).toFixed(4));

Vue.filter('formatNum', val => {
    if(val || val >= 0 || val <= 0){
        let str = val + "";
        if (str.endsWith('.000000')) {
            return str.replace('.000000', '');
        } else if (str.endsWith('.00000')) {
            return str.replace('.00000', '');
        } else if (str.endsWith('.0000')) {
            return str.replace('.0000', '');
        } else if (str.endsWith('.000')) {
            return str.replace('.000', '');
        } else if (str.endsWith('.00')) {
            return str.replace('.00', '');
        } else if (str.endsWith('.0')) {
            return str.replace('.0', '');
        } else {
            return val
        }
    }else{
        return val
    }
});

Vue.filter("shortEth", function (value) {
    if (!value) return value;

    return `
  ${value.substr(0, 4)}...${value.substr(value.length - 4, value.length)}
  `;
})
