<template>
    <footer>
        <div class="footer">
            <div class="container">
                <article class="row">
                    <aside class="col-md-7">
                        <div class="copyright">
                            <ul>
                                <li>{{ 'copyright' | lang }}</li>
                                <li><router-link :to="{ name: 'privacy-policy' }">{{ 'privacy_policy' | lang }}</router-link></li>
                                <li><router-link :to="{ name: 'about' }">{{ 'about' | lang }}</router-link></li>
<!--                                <li><a href="#">{{ 'terms_and_conditions' | lang }}</a></li>-->
                            </ul>
                        </div>
                    </aside>
                    <aside class="col-md-5">
                        <ul class="social_icon">
                            <!-- <li><a href="https://twitter.com/MultisendOrg" target="_blank"><i class="fab fa-twitter"></i></a>
                            </li> -->
                          <li><a href="https://www.facebook.com/Multisendorg-240936286984431" target="_blank"><i class="fab fa-facebook"></i></a>
                            </li>
                            <li>
                                <a href=" https://www.linkedin.com/company/multisend" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                            </li>
                            <li>
                                <a href="https://t.me/multisend" target="_blank"><i class="fab fa-telegram-plane"></i></a>
                            </li>
                            <li>
                                <a href="https://www.reddit.com/user/multisend" target="_blank"><i class="fab fa-reddit"></i></a>
                            </li>
                            <li>
                                <a href="https://multisend.medium.com" target="_blank"><i class="fab fa-medium"></i></a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UCyhQCL5ZDQzLTREeicUpFJw" target="_blank"><i class="fab fa-youtube"></i></a>
                            </li>
                          <li>
                            <a target="_blank" href="https://etherscan.io/address/0x0B36b0F351ea8383506F596743a2DA7DCa204cc3#code">
                              <img src="https://etherscan.io/images/logo-ether-white.png?v=0.0.2" style="width: 80px;"/>
                            </a>
                          </li>
                          <!-- <li>
                            <a target="_blank" href="https://dappradar.com/ethereum/other/multisend-org">
                              <img src="https://dappradar.com/logo.svg" style="width: 80px;"/>
                            </a>
                          </li> -->
                        </ul>
                    </aside>
                </article>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>

</style>
