<template>
  <header>
    <div class="main_header">
      <div>
        <nav
          class="navbar navbar-expand-lg navbar-light"
          style="padding-right: 50px"
        >
          <div>
            <a href="https://tronsender.com/" target="_blank"
              ><img
                style="width: 40px; margin-right: 10px; margin-left: 10px"
                src="../assets/images/TRX_BOX.png"
                alt="tronsender.com"
            /></a>
            <a href="http://binancesender.com/" target="_blank"
              ><img
                style="width: 40px; margin-right: 10px; margin-left: 10px"
                src="../assets/images/BNB_BOX.png"
                alt="binancesender.com"
            /></a>
            <a href="https://polygonsender.com/" target="_blank"
              ><img
                style="width: 55px"
                src="../assets/images/poly.png"
                alt="polygonsender.com"
            /></a>
            <!-- <a href="https://solanasender.com/" target="_blank"
              ><img
                style="width: 55px;"
                src="../assets/images/sola.png"
                alt="solanasender.com"
            /></a> -->
          </div>
          <router-link class="navbar-brand" :to="{ name: 'index' }" style="">
            <img
              style=""
              src="../assets/images/logo_new.png"
              alt="Multisend.org"
            />
          </router-link>
          <button
            class="navbar-toggler"
            @click="show = !show"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="collapse navbar-collapse"
            :class="{ show }"
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav ml-auto mt-1">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'tutorial' }"
                  >{{ "instructions" | lang }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'affiliate-programme' }"
                  >{{ "affiliate_programme" | lang }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'analytics' }">
                  {{ "analytics" | lang }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'about' }">
                  {{ "about" | lang }}
                </router-link>
              </li>
              <li class="nav-item">
                <ul class="social_icon">
                  <!--                                    <li>-->
                  <!--                                        <a class="nav-link" href="https://twitter.com/MultisendOrg" target="_blank"><i-->
                  <!--                                                class="fab fa-twitter"></i></a>-->
                  <!--                                    </li>-->
                  <!--                                    <li>-->
                  <!--                                        <a class="nav-link" href="https://t.me/multisend" target="_blank"><i-->
                  <!--                                                class="fab fa-telegram-plane"></i></a>-->
                  <!--                                    </li>-->
                  <!--                                    <li>-->
                  <!--                                        <a class="nav-link" href="https://www.youtube.com/watch?v=KkKeGyGdqXQ"-->
                  <!--                                           target="_blank"><i class="fab fa-youtube"></i></a>-->
                  <!--                                    </li>-->
                  <li>
                    <a
                      class="nav-link"
                      target="_blank"
                      href="https://etherscan.io/address/0x0B36b0F351ea8383506F596743a2DA7DCa204cc3#code"
                    >
                      <img
                        src="https://etherscan.io/images/logo-ether.png?v=0.0.2"
                        style="width: 75px"
                      />
                    </a>
                  </li>
                </ul>
              </li>

              <li class="nav-item">
                <b-dropdown size="sm" split>
                  <template v-slot:button-content>
                    <img
                      :src="`https://flagsapi.com/GB/flat/16.png`"
                      alt=""
                    />
                    English
                  </template>
                  <b-dropdown-item
                    @click="changeLang('gb')"
                    v-if="lang !== 'gb'"
                  >
                    <img
                      :src="`https://flagsapi.com/GB/flat/16.png`"
                      alt=""
                    />
                    English
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="changeLang('kr')"
                    v-if="lang !== 'kr'"
                  >
                    <img
                      :src="`https://flagsapi.com/KR/flat/16.png`"
                      alt=""
                    />
                    Korean
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="changeLang('ru')"
                    v-if="lang !== 'ru'"
                  >
                    <img
                      :src="`https://flagsapi.com/RU/flat/16.png`"
                      alt=""
                    />
                    Russian
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="changeLang('cn')"
                    v-if="lang !== 'cn'"
                  >
                    <img
                      :src="`https://flagsapi.com/CN/flat/16.png`"
                      alt=""
                    />
                    Chinese
                  </b-dropdown-item>
                </b-dropdown>
              </li>

              <li class="nav-item">
                <div v-if="!account">
                  <b-button @click="connect">Connect</b-button>
                </div>
                <div v-else>
                  <span>{{ account | shortEth }}</span>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ethers } from "ethers";

export default {
  name: "Header",
  computed: {
    ...mapGetters(["lang", "account"]),
  },
  methods: {
    ...mapActions(["changeLang"]),
    async connect() {
      await window.ethereum.enable();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const chain = await provider.getNetwork();

      await this.$store.dispatch("bootstrapWeb3", { provider, signer, chain });
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style scoped lang="scss">
.navbar-nav {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;

    .nav-item {
      padding-left: 0 !important;
    }
  }
}
</style>
