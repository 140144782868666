<template>
  <div>
    <Loading v-show="loading" />
    <section v-show="!loading && wizard.currentStep === 1" class="main_wrapp">
      <div class="container">
        <fieldset>
          <div class="main_form">
            <h3 class="text-center">Get started here</h3>
            <p class="text-center">Select token and add data to proceed</p>
            <!--                        <p v-if="$route.query.referrer">-->
            <!--                            Affiliate Address: <code>{{ $route.query.referrer }}</code>-->
            <!--                        </p>-->
            <div class="token_address">
              <div class="form-group">
                <label for="address">{{ "token_address" | lang }}</label>
                <v-select
                  id="address"
                  label="contract_name"
                  :options="tokensInAccount"
                  @input="setSelected"
                  :filter-by="filterBy"
                >
                  <template v-slot:option="option">
                    ({{ option.contract_ticker_symbol }})
                    {{ option.contract_name
                    }}{{
                      option.contract_address &&
                      option.contract_address !=
                        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
                        ? " - " + option.contract_address
                        : ""
                    }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="decimal">
              <div class="form-group">
                <label for="address">{{ "decimals" | lang }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="decimals"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="address_csv">
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label>{{ "csv_data" | lang }}</label>
                  </div>
                  <div class="col">
                    <b-button
                      v-if="!inputCSVManually"
                      variant="link"
                      class="float-right"
                      @click="toggleInputManually"
                    >
                      Insert Manually
                    </b-button>
                    <b-button
                      v-if="inputCSVManually"
                      variant="link"
                      class="float-right"
                      @click="toggleInputManually"
                    >
                      Upload File
                    </b-button>
                  </div>
                </div>
                <div v-if="!inputCSVManually">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                    :useCustomSlot="true"
                    :duplicateCheck="true"
                    @vdropzone-file-added="onFileAdded"
                  >
                    <div class="dropzone-custom-content">
                      <h4 class="dropzone-custom-title">
                        Drag and drop the CSV
                      </h4>
                      <div class="subtitle">
                        ...or click to select a file from your computer
                      </div>
                    </div>
                  </vue-dropzone>
                </div>
                <div v-else>
                  <b-form-textarea
                    id="csv-group-data"
                    v-model="form.csvData"
                    rows="15"
                    @input="onValidateInputs"
                    max-rows="250"
                  />
                </div>
              </div>
              <div class="upload_file row">
                <div class="col">
                  <router-link
                    class="text-danger font-weight-bold"
                    :to="{ name: 'tutorial' }"
                    style="text-decoration: underline"
                  >
                    {{ "instructions" | lang }}
                  </router-link>
                </div>
                <div class="col text-right">
                  <a
                    href="/CSV_Example.csv"
                    download
                    class="text-danger font-weight-bold"
                    style="text-decoration: underline"
                    >Download Example CSV</a
                  >
                </div>
              </div>
              <div class="my-2 alert alert-danger" v-if="invalidRows.length">
                <div>
                  The following rows will not be part of the airdrop as they are
                  invalid:
                </div>
                <table class="table-striped my-3" style="width: 100%">
                  <thead>
                    <tr>
                      <th class="p-2">Address</th>
                      <th class="p-2">Value</th>
                      <th class="p-2">Line</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, idx) in invalidRows" :key="idx">
                      <td class="p-2">{{ row[0] || "" }}</td>
                      <td class="p-2">{{ row[1] || "" }}</td>
                      <td class="p-2">{{ row[2] || "" }}</td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <b-button variant="link" @click="removeInvalidRows"
                    >Delete all invalid</b-button
                  >
                </div>
              </div>
              <div
                class="my-2 alert alert-danger"
                v-if="duplicateAddresses.length"
              >
                <div>Duplicate addresses have been found:</div>
                <table class="table-striped my-3" style="width: 100%">
                  <tbody>
                    <tr v-for="(address, idx) in duplicateAddresses" :key="idx">
                      <td class="p-2">{{ address || "" }}</td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <b-button
                    variant="link"
                    @click="keepFirstAddressFromAddressDuplicates"
                    >Keep the first one
                  </b-button>
                  |
                  <b-button
                    variant="link"
                    @click="combineBalancesFromAddressDuplicates"
                    >Combine Balances
                  </b-button>
                </div>
              </div>
            </div>
            <button
              :disabled="!valid"
              @click="nextStep(1)"
              class="btn btn-block mt-3"
              :class="{
                'btn-danger': !valid,
                'btn-success': valid,
                'not-allowed': !valid,
              }"
            >
              {{ "send" | lang }}
            </button>
          </div>
        </fieldset>
      </div>
    </section>
    <Faq v-show="!loading && wizard.currentStep === 1" />
    <section
      class="main_wrapp approve_main"
      v-show="!loading && wizard.currentStep === 2"
    >
      <div class="container">
        <h3 class="text-center">Approval</h3>
        <p class="text-center">
          This will authorise the amount of tokens you wish to send - once you
          have completed this step you will be taken to the next step where you
          will send your tokens.
        </p>
        <fieldset>
          <div class="approve">
            <article class="row">
              <aside class="col-md-4">
                <div class="approve_inner">
                  <h4>
                    {{ airdropData.allowance | to4dp | formatNum }} {{ symbol }}
                  </h4>
                  <p>{{ "your_current_multisender" | lang }}</p>
                </div>
              </aside>
              <aside class="col-md-4">
                <div class="approve_inner">
                  <h4>{{ airdropData.tokenCount }}</h4>
                  <p>{{ "total_number_of_tokens" | lang }}</p>
                </div>
              </aside>
              <aside class="col-md-4">
                <div class="approve_inner">
                  <h4>{{ airdropData.balance | to4dp | formatNum }}</h4>
                  <p>{{ "your_token_balance" | lang }}</p>
                </div>
              </aside>
            </article>
          </div>
          <span
            v-if="Number(airdropData.balance) <= Number(airdropData.tokenCount)"
            style="color: red; text-align: center"
            >*Insufficient token balance</span
          ><br />
          <button
            :disabled="
              approving ||
              !airdropData.balance ||
              !airdropData.tokenCount ||
              Number(airdropData.balance) <= Number(airdropData.tokenCount)
            "
            @click="approveAllowance"
            class="btn btn-danger"
          >
            {{ "next" | lang }}
          </button>
        </fieldset>
      </div>
    </section>
    <section
      class="main_wrapp multisend"
      v-show="!loading && wizard.currentStep === 3"
    >
      <div class="container">
        <h3 class="text-center">Send Tokens</h3>
        <p class="text-center">
          Select your gas fee in your wallet and check your Multisend
          information before clicking send and confirming your transactions.
        </p>
        <fieldset>
          <div class="approve">
            <article class="row">
              <aside class="col-md-4">
                <div class="approve_inner">
                  <h4>{{ airdropData.addressCount }}</h4>
                  <p>{{ "total_number_of_addresses" | lang }}</p>
                </div>
              </aside>
              <aside class="col-md-4">
                <div class="approve_inner">
                  <h4>{{ airdropData.tokenCount }} {{ symbol }}</h4>
                  <p>{{ "total_number_of_tokens" | lang }}</p>
                </div>
              </aside>
              <aside class="col-md-4">
                <div class="approve_inner">
                  <h4>
                    {{ airdropData.balance | to4dp | formatNum }} {{ symbol }}
                  </h4>
                  <p>{{ "your_token_balance" | lang }}</p>
                </div>
              </aside>
              <aside class="col-md-4">
                <div class="approve_inner">
                  <h4>{{ airdropData.txCount }}</h4>
                  <p>{{ "total_number_of_transactions" | lang }}</p>
                </div>
              </aside>
              <aside class="col-md-4">
                <div class="approve_inner">
                  <h4>{{ accountBalance | to4dp | formatNum }} ETH</h4>
                  <p>{{ "your_eth_balance" | lang }}</p>
                </div>
              </aside>
              <aside class="col-md-4">
                <div class="approve_inner" v-if="airdropData && pricePerTx">
                  <h4>
                    <!-- {{
                      (parseInt(airdropData.txCount) * parseFloat(pricePerTx))
                        | to4dp
                        | formatNum
                    }} -->
                    {{
                      [
                        parseInt(airdropData.txCount) * parseFloat(pricePerTx),
                        gasFee,
                      ] | toShowGas
                    }}
                    ETH
                    <!-- {{ gasFee | toGasFormat | formatNum }} -->
                    <small class="text-muted">with GAS</small>
                  </h4>
                  <vue-slider
                    v-model="gasFee"
                    :marks="marks"
                    v-bind="{
                      value: gasFee,
                      min: this.minFees,
                      max: this.maxFees,
                      interval: 0.1,
                    }"
                  />
                  <p style="margin-top: 25px">
                    {{ "approximate_cost_of_operation" | lang }}
                  </p>
                </div>
              </aside>
            </article>
          </div>

          <span
            v-if="
              Number(accountBalance) <=
              Number(parseInt(airdropData.txCount) * parseFloat(pricePerTx))
            "
            style="color: red; text-align: center"
            >*Insufficient token balance</span
          >
          <span
            v-if="
              form &&
              form.token == '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee' &&
              Number(accountBalance) <=
                Number(airdropData.tokenCount) +
                  Number(parseInt(airdropData.txCount) * parseFloat(pricePerTx))
            "
            style="color: red; text-align: center"
            >*Insufficient token balance</span
          ><br />
          <button
            :disabled="
              gasFee <= 0 ||
              Number(accountBalance) <=
                Number(
                  parseInt(airdropData.txCount) * parseFloat(pricePerTx)
                ) ||
              (form &&
                form.token == '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee' &&
                Number(accountBalance) <=
                  Number(airdropData.tokenCount) +
                    Number(
                      parseInt(airdropData.txCount) * parseFloat(pricePerTx)
                    ))
            "
            @click="onSubmit"
            v-if="!airdropComplete && airdropTxs.length === 0"
            class="btn btn-danger"
          >
            {{ "next" | lang }}
          </button>
          <ul class="list-group mt-4 mb-4" v-if="airdropTxs.length > 0">
            <li
              class="list-group-item"
              v-for="(txUrl, idx) in airdropTxs"
              :key="idx"
            >
              Transaction {{ idx + 1 }} of {{ airdropTxs.length }} sent
              <a :href="txUrl" target="_blank" class="float-right">link</a>
            </li>
          </ul>
        </fieldset>
      </div>
    </section>
    <section
      class="main_wrapp multisend"
      v-show="!loading && wizard.currentStep === 4"
    >
      <div class="container">
        <h3 class="text-success text-center">
          Congratulations! You have successfully finished your multisend.
        </h3>

        <ul class="list-group mt-4 mb-4">
          <li
            class="list-group-item"
            v-for="(txUrl, idx) in airdropTxs"
            :key="idx"
          >
            Transaction {{ idx + 1 }} successful
            <a :href="txUrl" target="_blank" class="float-right">link</a>
          </li>
        </ul>
        <button
          @click="startOver"
          v-if="airdropComplete || true"
          class="btn btn-danger"
        >
          {{ "proceed" | lang }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import Faq from "../components/Faq";
import { mapGetters, mapState } from "vuex";
import utils from "../utils";
import ERC20Airdropper from "../truffleconf/ERC20Airdropper";
import { ethers } from "ethers";
import erc20Abi from "../abi/erc20.abi";
import _ from "lodash";
import "rangeslider.js";
import $ from "jquery";
import Loading from "../components/Loading";
import notify from "bnc-notify";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import toastr from "toastr";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

const notifyInstance = notify({
  dappId: "93470207-965c-447c-b3c5-31851a1fb2e7",
  networkId: 1,
  darkMode: true,
  desktopPosition: "topRight",
});

export default {
  name: "Index",
  components: { VueSlider, Loading, Faq, vueDropzone: vue2Dropzone },
  computed: {
    ...mapState(["tokensInAccount", "tokenFees"]),
    ...mapGetters([
      "contracts",
      "account",
      "accountBalance",
      "chain",
      "signer",
      "provider",
    ]),
    airdropData() {
      return {
        addressCount: this.rowLen,
        tokenCount: this.totalValue,
        allowance: this.allowance,
        balance: this.balance,
        txCount: this.batchLen,
      };
    },
    hasEnoughAllowance() {
      return (
        this.allowance &&
        this.totalValue &&
        Number(this.allowance) >= Number(this.totalValue)
      );
    },
    totalStepsRequiredToSetupAirdrop() {
      return this.hasEnoughAllowance ? 2 : 3;
    },
    currentStep() {
      if (
        this.totalStepsRequiredToSetupAirdrop === 2 &&
        this.wizard.currentStep === 3
      ) {
        return 2;
      } else {
        return this.wizard.currentStep;
      }
    },
    invalidRows() {
      if (!this.form.csvData) return [];
      const csvArray = this.csvDataToArrays(this.form.csvData);

      const validRows = [];
      const invalidRows = [];
      csvArray.forEach((row, idx) => {
        // Row invalid if true
        if (row.length === 1) {
          invalidRows.push([
            row[0] && row[0].trim() ? row[0].trim() : "Unknown",
            "Unknown",
            idx + 1,
          ]);
          return;
        }

        // Check address
        const address = row[0] && row[0].trim() ? row[0].trim() : null;
        if (!address) {
          invalidRows.push([...row, idx + 1]);
          return;
        }
        try {
          ethers.utils.getAddress(address);
        } catch (e) {
          invalidRows.push([...row, idx + 1]);
          return;
        }

        //Validate value supplied
        if (row.length === 2 && !row[1]) {
          // Empty value supplied
          invalidRows.push([row[0], "Unknown", idx + 1]);
          return;
        }

        if (row.length > 2) {
          // Too many delimiters / values supplied
          invalidRows.push(["Unknown", "Unknown", idx + 1]);
          return;
        }
      });

      return invalidRows;
    },
    csvDataFilteredForValidRowsOnly() {
      if (!this.form.csvData) return [];

      return (this.csvDataToArrays(this.form.csvData) || []).filter((row) => {
        // Row invalid if true
        if (row.length === 1) {
          return false;
        }

        if (row.length === 2 && !row[1]) {
          // Empty value supplied
          return false;
        }

        if (row.length > 2) return false;

        const address = row[0] && row[0].trim() ? row[0].trim() : null;
        if (!address) return false;
        try {
          ethers.utils.getAddress(address);
          return true;
        } catch (e) {
          return false;
        }
      });
    },
    duplicateAddresses() {
      const duplicateAddresses = [];
      const addressCount = {};
      this.csvDataFilteredForValidRowsOnly.forEach((row, idx) => {
        const address = row[0];
        if (!addressCount[address]) {
          addressCount[address] = 1;
        } else if (addressCount[address] === 1) {
          duplicateAddresses.push(address);
          addressCount[address] = addressCount[address] + 1;
        }
      });

      return duplicateAddresses;
    },
    notEnoughCredits() {
      if (!this.credits || !this.batchLen) return false;
      const credits = Number(this.credits);
      return credits > 0 && Number(this.batchLen) > credits;
    },
    etherscanUrl() {
      const subdomain = this.chain.chainId === 42 ? "kovan." : "";

      return `https://${subdomain}etherscan.io`;
    },
  },
  data() {
    return {
      mazBatchSize: 200,
      credits: null,
      pricePerTxInWei: null,
      pricePerTx: null,
      gasFee: 0,
      marks: [],
      minFees: 0,
      maxFees: 0,
      balance: null,
      valid: null,
      rowLen: null,
      batchLen: null,
      totalValue: null,
      allowance: null,
      symbol: null,
      decimals: null,
      approving: false,
      approveTx: null,
      airdropTxs: [],
      confirmedAirdropTxs: [],
      airdropping: false,
      airdropComplete: false,
      range: 1.5,
      form: {
        token: null,
        file: null,
        amountToApprove: null,
        csvData: null,
      },
      wizard: {
        currentStep: 1,
        stepInfo: "Configure Airdrop Params (Push)",
      },
      loading: false,
      clearInvalidRows: false,
      inputCSVManually: false,
      dropzoneOptions: {
        url: "https://",
        thumbnailHeight: 120,
        thumbnailWidth: 120,
        autoProcessQueue: false,
        maxFilesize: 5,
        maxFiles: 1,
        minFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: ".csv",
      },
    };
  },
  methods: {
    toggleInputManually() {
      this.inputCSVManually = !this.inputCSVManually;
      this.onValidateInputs();
    },
    onFileAdded(file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.form.csvData = new TextDecoder("utf-8").decode(reader.result);
        this.inputCSVManually = true;
        this.onValidateInputs();
      };
      reader.readAsArrayBuffer(file);
      this.onValidateInputs();
    },
    keepFirstAddressFromAddressDuplicates() {
      const firstAddressFound = {};
      const csvDataWithFirstDuplicateKept =
        this.csvDataFilteredForValidRowsOnly.filter((row) => {
          const address = row[0];
          let include = true;
          if (!firstAddressFound[address]) {
            firstAddressFound[address] = true;
          } else {
            include = !_.includes(this.duplicateAddresses, row[0]);
          }

          return include;
        });
      this.form.csvData = this.csvArrayToString(csvDataWithFirstDuplicateKept);
    },
    combineBalancesFromAddressDuplicates() {
      const totalBalances = {};
      this.csvDataFilteredForValidRowsOnly.forEach((row) => {
        const address = row[0];
        const value = parseFloat(row[1]);

        if (!totalBalances[address]) {
          totalBalances[address] = value;
        } else {
          totalBalances[address] = totalBalances[address] + value;
        }
      });

      this.form.csvData = this.csvArrayToString(
        Object.keys(totalBalances).map((address) => [
          address,
          totalBalances[address],
        ])
      );
    },
    removeInvalidRows() {
      this.form.csvData = this.csvArrayToString(
        this.csvDataFilteredForValidRowsOnly
      );
      this.onValidateInputs();
    },
    csvArrayToString(csvArray) {
      return _.join(
        csvArray.map((row) => _.join(row, ",")),
        "\n"
      );
    },
    setSelected(value) {
      this.form.token = value ? value.contract_address : "";
      this.checkAllowance();
      this.onValidateInputs();
    },
    filterBy(option, label, search) {
      if (
        option.contract_ticker_symbol &&
        option.contract_ticker_symbol
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1
      ) {
        return true;
      }
      if (
        option.contract_name &&
        option.contract_name.toLowerCase().indexOf(search.toLowerCase()) > -1
      ) {
        return true;
      }
      if (
        option.contract_address &&
        option.contract_address.toLowerCase().indexOf(search.toLowerCase()) > -1
      ) {
        return true;
      }
      return false;
    },
    async getPricePerTx() {
      const { ERC20Airdropper } = this.contracts;
      this.pricePerTxInWei = await ERC20Airdropper.pricePerTx();
      this.pricePerTx = ethers.utils.formatEther(this.pricePerTxInWei);
    },
    nextStep(from) {
      this.loading = true;
      if (from === 1) {
        let skipToStep3 = false;
        if (
          this.form.token &&
          this.account &&
          this.form.token == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        ) {
          skipToStep3 = true;
        } else {
          skipToStep3 = this.totalStepsRequiredToSetupAirdrop === 2;
        }
        this.wizard.currentStep = skipToStep3 ? 3 : 2;
        if (skipToStep3) {
          this.minFees = this.tokenFees ? this.tokenFees.safeLow / 10 : 0;
          this.maxFees = this.tokenFees ? this.tokenFees.fastest / 10 : 0;
          let avg = this.tokenFees ? this.tokenFees.average / 10 : 0;
          let fast = this.tokenFees ? this.tokenFees.fast / 10 : 0;
          this.gasFee = this.tokenFees ? this.tokenFees.fastest / 10 : 0;
          this.marks = [this.minFees, avg, fast, this.maxFees];
        }
      } else {
        this.wizard.currentStep = 3;
        this.minFees = this.tokenFees ? this.tokenFees.safeLow / 10 : 0;
        this.maxFees = this.tokenFees ? this.tokenFees.fastest / 10 : 0;
        this.gasFee = this.tokenFees ? this.tokenFees.fastest / 10 : 0;
        let avg = this.tokenFees ? this.tokenFees.average / 10 : 0;
        let fast = this.tokenFees ? this.tokenFees.fast / 10 : 0;
        this.marks = [this.minFees, avg, fast, this.maxFees];
      }
      if (this.wizard.currentStep === 2) {
        this.wizard.stepInfo = "Increase Airdropper Token Allowance";
      } else {
        this.wizard.stepInfo = "Confirm Airdrop";
      }
      this.loading = false;
    },
    goBack(from) {
      this.wizard.currentStep = from - 1;
    },
    startOver() {
      this.loading = true;
      this.airdropComplete = false;
      this.airdropTxs = [];
      this.confirmedAirdropTxs = [];
      this.goBack(2);
      this.loading = false;
    },
    async onSubmit() {
      if (this.form.token && this.form.csvData) {
        this.form.csvData = this.form.csvData.replace(/\n$/, ""); // drop last newline

        if (parseFloat(this.allowance) < parseFloat(this.totalValue)) {
          alert("Allowance not enough");
          return;
        }

        this.airdropping = true;

        let nonce = await this.provider.getTransactionCount(this.account);

        const chunks = _.chunk(
          this.csvDataFilteredForValidRowsOnly,
          this.mazBatchSize
        );

        _.map(chunks, (chunk, idx) => {
          // console.log(
          //   idx + 1 > Number(this.credits) ? this.pricePerTxInWei : 0
          // );

          const addresses = chunk.map((vals) =>
            vals[0].replace(/\n$/, "").replace(/\r$/, "").trim()
          );
          const values = chunk.map((vals) =>
            ethers.utils.parseUnits(
              vals[1].replace(/\n$/, "").replace(/\r$/, "").trim(),
              this.decimals
            )
          );

          let referrer = ZERO_ADDRESS;
          if (this.$route.query.referrer) {
            try {
              referrer = ethers.utils.getAddress(this.$route.query.referrer);
              // console.log("valid referral", referrer);
            } catch (e) {
              // console.log("invalid referral", this.$route.query.referrer);
            }
          }

          const { ERC20Airdropper } = this.contracts;
          const contractCall = {
            methodName: "transfer",
            params: [this.form.token, referrer, addresses, values],
          };

          const contractETHCall = {
            methodName: "transferETH",
            params: [referrer, addresses, values],
          };

          const updateUIWhenDone = async (tx) => {
            await tx.wait(1);
            this.confirmedAirdropTxs.push(tx);

            if (this.confirmedAirdropTxs.length === this.airdropTxs.length) {
              this.wizard.currentStep = 4;
              this.airdropping = false;
              this.form.csvData = null;
              this.checkAllowance();
              this.checkCredits();
              this.airdropComplete = true;
            }
          };

          const sendTransaction = async () => {
            let gasPrice = ethers.utils.parseUnits(this.gasFee + "", 9);
            try {
              if (
                this.form.token &&
                this.account &&
                this.form.token == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
              ) {
                let txCharges = ethers.BigNumber.from(this.pricePerTxInWei);
                let totalCharge = txCharges.add(0);
                for (let val of values) {
                  totalCharge = totalCharge.add(val.toString());
                }
                // transferETH
                const tx = await ERC20Airdropper.transferETH(
                  ...contractETHCall.params,
                  {
                    value: idx + 1 > Number(this.credits) ? totalCharge : 0,
                    nonce: ++nonce,
                    gasPrice: gasPrice,
                  }
                );

                this.airdropTxs.push(`${this.etherscanUrl}/tx/${tx.hash}`);
                updateUIWhenDone(tx);
                return tx.hash;
              } else {
                try {
                  let ethVal =
                    idx + 1 > Number(this.credits) ? this.pricePerTxInWei : 0;
                  console.log(
                    "...contractCall.params",
                    ...contractCall.params,
                    ethVal
                  );
                  const tx = await ERC20Airdropper.transfer(
                    ...contractCall.params,
                    {
                      value:
                        idx + 1 > Number(this.credits)
                          ? this.pricePerTxInWei
                          : 0,
                      nonce: ++nonce,
                      gasPrice: gasPrice,
                    }
                  );

                  this.airdropTxs.push(`${this.etherscanUrl}/tx/${tx.hash}`);
                  updateUIWhenDone(tx);
                  return tx.hash;
                } catch (e) {
                  toastr.error("Oops! Something went wrong");
                }
              }
            } catch (e) {
              console.error("===>", e);
              this.airdropping = false;
              return null;
            }
          };

          notifyInstance.transaction({
            sendTransaction,
            contractCall,
          });
        });
      }
    },
    async onValidateInputs() {
      // console.log("onValidateInputs");

      if (!this.form.csvData) return false;

      let allValuesValid = null;
      let values = null;

      const csvArray = this.csvDataToArrays(this.form.csvData) || [];
      const addresses = csvArray.map((vals) => vals[0].trim());

      try {
        values = csvArray.map((vals) => {
          if (vals.length === 1) return "-1"; // No value has been supplied
          if (vals.length === 2 && !vals[1]) return "-1"; // Empty value supplied
          if (vals.length > 2) return "-1"; // Too many delimiters / values supplied
          return ethers.utils.parseUnits(vals[1].trim(), this.decimals);
        });
        // console.log("values", values);
        const invalidValues = values.filter(
          (value) => value.toString() === "-1"
        );
        allValuesValid = !invalidValues.length;
      } catch (e) {
        console.error(e);
        allValuesValid = false;
      }

      let allAddressesValid = addresses.every((addrToCheck) => {
        if (!addrToCheck) return false;
        try {
          ethers.utils.getAddress(addrToCheck);
          return true;
        } catch (e) {
          console.error(e);
          return false;
        }
      });

      // console.log("Addresses", addresses);
      // console.log("Addresses VALID", allAddressesValid);

      // console.log("Values", values && values.map((value) => value.toString()));
      // console.log("Values VALID", allValuesValid);

      this.valid = allAddressesValid && allValuesValid;

      if (this.valid) {
        this.rowLen = values.length;
        this.totalValue =
          values &&
          ethers.utils.formatUnits(
            values
              .reduce(
                (accum, value) => accum.add(value),
                ethers.BigNumber.from("0")
              )
              .toString(),
            this.decimals
          );
        this.batchLen = Math.ceil(values.length / this.mazBatchSize);
      }
      return allAddressesValid && allValuesValid;
    },
    async checkAllowance() {
      if (
        this.form.token &&
        this.account &&
        this.form.token == "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      ) {
        this.symbol = "ETH";
        this.decimals = 18;

        const weiBalance = await this.provider.getBalance(this.account);
        this.balance = ethers.utils.formatUnits(weiBalance, this.decimals);
        this.allowance = "";
      } else if (this.form.token && this.account) {
        const airdropperAddress = utils.getContractAddressFromTruffleConf(
          ERC20Airdropper,
          this.chain.chainId
        );
        const erc20 = new ethers.Contract(
          this.form.token,
          erc20Abi,
          this.signer
        );

        this.symbol = await erc20.symbol();
        this.decimals = await erc20.decimals();

        const weiBalance = await erc20.balanceOf(this.account);
        this.balance = ethers.utils.formatUnits(weiBalance, this.decimals);
        // console.log("balance", weiBalance.toString());
        this.allowance = ethers.utils.formatUnits(
          await erc20.allowance(this.account, airdropperAddress),
          this.decimals
        );
        // console.log("allowance", this.allowance.toString());
      } else {
        this.decimals = "";
        this.symbol = "";
        this.balance = "";
        this.allowance = "";
      }
    },
    async approveAllowance() {
      if (this.form.token && this.account) {
        this.approving = true;
        const airdropperAddress = utils.getContractAddressFromTruffleConf(
          ERC20Airdropper,
          this.chain.chainId
        );
        const erc20 = new ethers.Contract(
          this.form.token,
          erc20Abi,
          this.signer
        );

        const contractCall = {
          methodName: "approve",
          params: [
            airdropperAddress,
            ethers.utils.parseUnits(this.totalValue, this.decimals),
          ],
        };

        const updateUIWhenDone = async (tx) => {
          this.approveTx = `${this.etherscanUrl}/tx/${tx.hash}`;

          const receipt = await tx.wait(1);

          // Refresh UI
          this.nextStep(2);
          this.checkAllowance();
          this.approving = false;
          this.approveTx = null;
          this.loading = false;
        };

        const sendTransaction = async () => {
          try {
            const tx = await erc20.approve(...contractCall.params);
            updateUIWhenDone(tx);
            this.loading = true;
            return tx.hash;
          } catch (e) {
            this.approving = false;
            this.approveTx = null;
            return null;
          }
        };

        await notifyInstance.transaction({
          sendTransaction,
          contractCall,
        });
      }
    },
    async checkCredits() {
      const { ERC20Airdropper } = this.contracts;
      this.credits = parseInt(
        (await ERC20Airdropper.credits(this.account)).toString()
      );
    },
    csvDataToArrays(data) {
      return data.split("\n").map((v) => v.split(","));
    },
    async connect() {
      await window.ethereum.enable();
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const chain = await provider.getNetwork();

        await this.$store.dispatch("bootstrapWeb3", {
          provider,
          signer,
          chain,
        });
      }
    },
  },
  async mounted() {
    await this.connect();

    if (this.contracts) {
      await this.checkCredits();
      this.getPricePerTx();

      if (this.form.token) {
        await this.checkAllowance();
      }
    }
    const self = this;
    $('input[type="range"]')
      .rangeslider({
        polyfill: false,
        onInit: function () {
          self.range = this.value;
        },
      })
      .on("input", function () {
        self.range = this.value;
      });
  },
  watch: {
    "form.file": function (newVal, oldVal) {
      if (newVal) {
        const reader = new FileReader();
        const onloadend = () => {
          this.form.csvData = reader.result;
          this.onValidateInputs();
        };
        reader.onloadend = onloadend;
        reader.readAsBinaryString(newVal);
      }
    },
    contracts: async function (newVal, oldVal) {
      if (newVal && !oldVal) {
        await this.checkCredits();
        this.getPricePerTx();

        if (this.form.token) {
          await this.checkAllowance();
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
