<template>
  <div>
    <b-table class="mt-6" striped hover :items="computedSends"></b-table>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import {ethers} from 'ethers';
import erc20Abi from "@/abi/erc20.abi.json";
import {mapGetters} from 'vuex';

export default {
  name: "Analytics",
  computed: {
    ...mapGetters(['signer']),
  },
  data() {
    return {
      computedSends: []
    }
  },
  methods: {
    async computeSends() {
      if (!this.sends || !this.sends.length) {
        this.computedSends = [];
      }

      if (!this.signer) {
        this.computedSends = this.sends.map(send => {
          const {
            token,
            caller,
            recipientCount,
            totalTokensSent
          } = send;

          return {
            token,
            caller,
            recipientCount,
            totalTokensSent
          };
        });
      }

      this.computedSends = await Promise.all(this.sends.map(async send => {
        const erc20 = new ethers.Contract(
            send.token,
            erc20Abi,
            this.signer,
        );

        const decimals = (await erc20.decimals());
        const symbol = (await erc20.symbol());

        const {
          token,
          caller,
          recipientCount,
          totalTokensSent
        } = send;

        return {
          token,
          caller,
          recipientCount,
          symbol,
          totalTokensSent: ethers.utils.formatUnits(totalTokensSent, decimals)
        };
      }));
    }
  },
  watch: {
    signer(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.computeSends();
      }
    }
  },
  apollo: {
    sends: {
      query: gql`query {
                    sends {
                      token
                      caller
                      recipientCount
                      totalTokensSent
                    }
                }`,
      result() {
        this.computeSends();
      }
    }
  },
}
</script>

<style scoped>

</style>
