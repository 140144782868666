<template>
    <section class="banner">
        <div class="container">
            <form id="msform">
                <h2 class="fs-title">{{ 'multisend_allows_distribute' | lang }}</h2>
                <h4 class="mb-0">{{ 'how_it_works' | lang }}</h4>
                <div class="row">
                    <div class="col-md-4 mb-0 mb-sm-0">
                        <div class="progress-inner">
                            <h3>STEP ONE <br/><br/>{{ 'connect_to_metamask' | lang }}</h3>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3 mb-sm-0">
                        <div class="progress-inner">
                            <h3>STEP TWO <br/><br/>{{ 'add_addresses' | lang }}</h3>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3 mb-sm-0">
                        <div class="progress-inner">
                            <h3>STEP THREE <br/><br/>{{ 'authorize_and_send' | lang }}</h3>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
export default {
    name: "Banner"
}
</script>

<style scoped>

</style>
