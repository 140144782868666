<template>
    <div id="app">
        <Header/>
        <main>
            <router-view name="banner"/>
            <router-view/>
        </main>
        <Footer/>
    </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
    name: 'App',
    components: {Footer, Header}
}
</script>

<style lang="scss">

</style>
