<template>
  <div>
    <section class="banner inner-banner">
      <div class="container text-center">
        <h2 class="fs-title">About Us</h2>
      </div>
    </section>

    <section class="blog">
      <div class="container">
        <div class="row">
          <div class="col-md-10 m-auto" style="text-align: left">
            <p>
              Thank you for choosing the
              <a href="https://multisend.org/">Multisend.org</a> ERC20 Token
              Mutisender app
            </p>
            <p>
              <b>Sending BNB, Ethereum or Tron tokens?</b>
            </p>
            <p>
              We have recently added the new Binance Bulk sender tool (BNB
              Multisender) to the platform alongside the existing Ethereum and
              Tron Token distribution app allowing users to Bulk send Binance
              Tokens. To use the Binance Bulk sender - simply select the Binance
              option on the top left of the website.
            </p>

            <p>
              <a href="https://multisend.org/">Multisend.org</a> has been live
              with no downtime for over one year, helping blockchain projects
              with bulk sending tokens. If you need to send a Binance Bulk send
              using either BNB tokens or BEP20 tokens, an Ethereum Bulk send
              with ETH and ERC20 tokens or Tron and TRC10 and TRC20 tokens. we
              have you covered.
            </p>
            <br/>
            <p>
              <b>Benefits of using a Token Multi Batch Sender </b>
            </p>
            <p>
              You can consider using the BNB Token Multisender, Ethereum token
              Multisender or Tron token Multisender for the following use cases:
            </p>

            <div style="margin-left:20%">
                <ul>
                    <li class="benefits-list">Crypto payroll</li>
                    <li class="benefits-list">Token sale distribution </li>
                    <li class="benefits-list">Crypto Competitions</li>
                    <li class="benefits-list">Crypto Airdrops</li>
                </ul>
            </div>
            <br/>

            <p>
                How does a Token Bulk Sender work? - In order to use the token multi batch sender please follow these steps:
            </p>
            <div class="alert alert-dark">
             <b>Step 1: </b> Visit the  <a href="https://multisend.org/">https://multisend.org/</a> website
            </div>
            <div class="alert alert-dark">
             <b>Step 2: </b> Prepare your token distribution address list (CSV or txt file)
            </div>
            <div class="alert alert-dark">
             <b>Step 3: </b> Make sure your wallet is on Ethereum mainnet for Ethereum, and for the BNB Tool connect to the BNB mainnet - the platform supports both Binance smart chain and Metamask wallets.
            </div>
            <div class="alert alert-dark">
             <b>Step 4: </b> Select the token you want to send from the drop down menu - make sure you are connected to the correct wallet with the tokens you wish to send.
            </div>
            <div class="alert alert-dark">
             <b>Step 5: </b> Click upload CSV or TXT file and select your address list (format must be address in colum one and the value in colum two - when entering the addresses manually or with a TXT file the format is address,value)
            </div>
            <div class="alert alert-dark">
             <b>Step 6: </b> Click Approve (This will prompt a transaction in your wallet asking to approve the amount you want to send.)
            </div>
            <div class="alert alert-dark">
             <b>Step 7: </b> Check the airdrop summary making sure the total quantity of tokens you are sending is correct
            </div>
            <div class="alert alert-dark">
             <b>Step 8: </b> Once happy Click send
            </div>
            <div class="alert alert-dark">
             <b>Step 9: </b> Receive confirmation and TXID - your Token Multisend is now complete 
            </div>
            <br/>
            <p>
                Thanks for choosing the Multisend Token Distribution App - visit the Multisend tutorial page to view a full Multisend demonstration - <a href="https://multisend.org/tutorial">Tutorial page</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data: function () {
    return {};
  },
  methods: {},
};
</script>