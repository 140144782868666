<template>
    <div class="mt-5 mb-5">
        <p class="text-center">This can take several minutes please be patient and do not close this page.</p>
        <img src="../assets/images/spinner.gif" class="mx-auto" alt="">
    </div>
</template>

<script>
export default {
    name: 'Loading'
}
</script>

<style scoped>
    img {
        display: block;
    }
</style>
