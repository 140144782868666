<template>
    <div class="faq">
        <div class="my-5">
            <h3 class="title">{{ 'instructions' | lang }}</h3>
            <div class="row pt-2">
                <div class="col-1 col-sm-2"></div>
                <div class="col-10 col-sm-8">
                    <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/400885169" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
                    <p><a href="https://vimeo.com/400885169">Demo</a> from <a href="https://vimeo.com/user110796519">Multisend.org</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
                </div>
                <div class="col-1 col-sm-2"></div>
            </div>
        </div>

        <div class="container" style="margin-top: 80px">
            <div class="col-md-12">
                <h3 class="title">{{ 'faqs' | lang }}</h3>
                <div class="accordion mt-5" id="accordionExample">

                    <b-card no-body>
                        <b-card-header header-tag="header" role="tab">
                            <h2 class="mb-0">
                                <button class="btn btn-link" type="button" v-b-toggle.collapseOne>
<!--                                    {{ 'how_does_it_work' | lang }}-->
                                    How does multisend.org work?
                                </button>
                            </h2>
                        </b-card-header>
                        <b-collapse id="collapseOne" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <p>You can send ERC20 tokens to multiple addresses in 3 simple steps. 1, Upload CSV 2, Approve 3, Send tokens
                                see our tutorial page for more information and tutorial video (link to <a href="https://multisend.org/tutorial">https://multisend.org/tutorial</a>).</p>
<!--                                <p>{{ 'you_can_send' | lang }}</p>-->
<!--                                <p>See our tutorial page for more information and tutorial video</p>-->
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body>
                        <b-card-header header-tag="header" role="tab">
                            <h2 class="mb-0">
                                <button class="btn btn-link" type="button" v-b-toggle.collapseTwo>
                                    <!-- {{ 'can_i_send' | lang }} -->
                                    What are the fees for using Multisend.org?
                                </button>
                            </h2>
                        </b-card-header>
                        <b-collapse id="collapseTwo" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <p>Find out how much your Multisend will cost using our fee calculator</p>
                                <!-- <p>{{ 'you_can_test' | lang }}</p> -->
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body>
                        <b-card-header header-tag="header" role="tab">
                            <h2 class="mb-0">
                                <button class="btn btn-link" type="button" v-b-toggle.collapseThree>
                                    <!-- {{ 'is_it_safe' | lang }} -->
                                    Can I test Multisend.org before sending my tokens?
                                </button>
                            </h2>
                        </b-card-header>
                        <b-collapse id="collapseThree" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <p>Yes Multisend has an inbuilt faucet which allows you to mint some test tokens to send and test the Multisend process before sending your tokens. 
                                Multisend can also be used on the Kovan test network.
                                </p>
                                <!-- <p>
                                    {{ 'multisend_uses_smart' | lang }}
                                    <a href="https://github.com/blockrockettech/erc20-airdropper/tree/master/airdropper-contracts/contracts" target="_blank" class="ml-5">link</a>
                                </p> -->
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    
                    <b-card no-body>
                        <b-card-header header-tag="header" role="tab">
                            <h2 class="mb-0">
                                <button class="btn btn-link" type="button" v-b-toggle.collapseFour>
                                    Is multisend.org safe? 
                                    <!-- {{ 'what_is_difference' | lang }} -->
                                </button>
                            </h2>
                        </b-card-header>
                        <b-collapse id="collapseFour" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <p>Yes, Multisend.org has processed thousands of token transfers. You can view the Multisend.org code along with previous transactions on Etherscan. 
                                </p>            
                                <!-- <p>
                                    <strong>{{ 'push' | lang }}</strong> - {{ 'this_is_the_most' | lang }}
                                </p>
                                <p>
                                    <strong>{{ 'pull' | lang }}</strong> - {{ 'airdrop_recipients_2' | lang }}
                                </p> -->
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body>
                        <b-card-header header-tag="header" role="tab">
                            <h2 class="mb-0">
                                <button class="btn btn-link" type="button" v-b-toggle.collapseFive>
                                    <!-- {{ 'is_it_safe' | lang }} -->
                                    Can I revoke approval for tokens?
                                </button>
                            </h2>
                        </b-card-header>
                        <b-collapse id="collapseFive" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <p>Yes if you have approved tokens but then decided not to send them please contact a Multisend admin and we can revoke this access. 
                                </p>
                                <!-- <p>
                                    {{ 'multisend_uses_smart' | lang }}
                                    <a href="https://github.com/blockrockettech/erc20-airdropper/tree/master/airdropper-contracts/contracts" target="_blank" class="ml-5">link</a>
                                </p> -->
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body>
                        <b-card-header header-tag="header" role="tab">
                            <h2 class="mb-0">
                                <button class="btn btn-link" type="button" v-b-toggle.collapseSix>
                                    <!-- {{ 'is_it_safe' | lang }} -->
                                    Do you have a referral programme?
                                </button>
                            </h2>
                        </b-card-header>
                        <b-collapse id="collapseSix" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <p>Yes - you can find more information on our affiliate page <router-link :to="{ name: 'affiliate-programme' }">here</router-link>. 
                                </p>
                                <!-- <p>
                                    {{ 'multisend_uses_smart' | lang }}
                                    <a href="https://github.com/blockrockettech/erc20-airdropper/tree/master/airdropper-contracts/contracts" target="_blank" class="ml-5">link</a>
                                </p> -->
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Faq"
}
</script>

<style scoped>

</style>
