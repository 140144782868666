<template>
    <div>
        <section class="banner inner-banner">
            <div class="container text-center">
                <h2 class="fs-title">Affiliate programme</h2>
            </div>
        </section>

        <section class="referral">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 m-auto">
                        <p>Earn 0.02 Ethereum by referring people to Multisend.org<br/>
                            Add your Ethereum address and click generate to create your unique referral link</p>


                        <form class="form-inline">
                            <label class="sr-only" for="inlineFormInputName2">Name</label>
                            <input type="text" v-model="ethAddress" class="form-control form-control-lg mb-2 mr-sm-2"
                                   id="inlineFormInputName2" placeholder="ETH Address" style="width: 560px">

                            <button type="button" class="btn btn-dark btn-lg mb-2" @click="generate">Generate</button>
                        </form>

                        <p class="my-5" v-if="link">
                            <code>{{ link }}</code>
                            <br/>
                            <button type="button" class="btn btn-outline-dark mt-2" @click="copyLink">Copy Link</button>
                        </p>

                        <div class="alert alert-dark">
                          This will earn 0.02 ETH every time someone uses the multisend.org platform using your referral link
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "AffilateProgramme",
        data: function () {
            return {
                ethAddress: '',
                link: '',
            };
        },
        methods: {
            generate() {
                this.link = `https://multisend.org/?referrer=${this.ethAddress}`;
            },
            copyLink() {
                const el = document.createElement('textarea');
                el.value = this.link;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                if (selected) {
                    document.getSelection().removeAllRanges();
                    document.getSelection().addRange(selected);
                }
                alert('Link copied ;)');
            },
        }
    };
</script>

<style scoped>

</style>
