<template>
    <div>
        <section class="banner tutorial_page">
            <div class="video_section">
                <div class="row pt-5">
                    <div class="col-1 col-sm-2"></div>
                    <div class="col-10 col-sm-8">
                        <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/400885169" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
                        <p><a href="https://vimeo.com/400885169">Multisend.org demo</a> from <a href="https://vimeo.com/user110796519">Multisend.org</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
                    </div>
                    <div class="col-1 col-sm-2"></div>
                </div>
            </div>
        </section>

        <section class="main_wrapp ">
            <div class="container">
                <div class="tutorial">

                    <h2>How it works</h2>

                    <div class="process">
                        <div class="process_inner">
                            <img src="../assets/images/process.png" alt="">
                            <div class="process_step one">
                                <p>Make sure your wallet is on Ethereum Mainnet</p>
                            </div>
                            <div class="process_step two">
                                <p>Select the token you wish to send from the drop down menu</p>
                            </div>
                            <div class="process_step three">
                                <p>Click upload CSV and select your airdrop list
                                    <span class="d-none d-sm-inline">(format must be address then balance separated by a comma and in the same column)</span></p>
                            </div>
                            <div class="process_step four">
                                <!--                           <p>Click Approve (this prompt a transaction to authorise your wallet to send the allocated amount)</p>-->
                                <p>Click Approve <span class="d-none d-sm-inline">(This will prompt a transactions in your wallet to approve amount you want to send.)</span></p>
                            </div>
                            <div class="process_step five">
                                <p>Check the airdrop summary</p>
                            </div>
                            <div class="process_step six">
                                <p>Click send</p>
                            </div>
                            <div class="process_step seven">
                                <p>Airdrop sending screen showing txids</p>
                            </div>
                        </div>
                    </div>
                    <h3>Multisend complete</h3>
                </div>
            </div>
        </section>

        <b-modal id="modal" :hide-footer="true">
            <template v-slot:modal-header="{ close }">
                <button type="button" class="close" @click="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </template>

            <template v-slot:default>
                <div class="video">
                    <iframe width="100%" height="350px" src="https://www.youtube.com/embed/yAoLSRbwxL8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </template>
        </b-modal>

<!--        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
<!--            <div class="modal-dialog" role="document">-->
<!--                <div class="modal-content">-->
<!--                    <div class="modal-header">-->
<!--                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--                            <span aria-hidden="true">&times;</span>-->
<!--                        </button>-->
<!--                    </div>-->
<!--                    <div class="modal-body">-->
<!--                        <div class="video">-->
<!--                            <iframe width="100%" height="350px" src="https://www.youtube.com/embed/yAoLSRbwxL8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
    name: "Tutorial"
}
</script>

<style scoped>

</style>
