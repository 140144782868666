import Vue from 'vue';
import Vuex from 'vuex';
import { ethers } from 'ethers';

import axios from 'axios';
import utils from '../utils';
import _ from 'lodash';
import ERC20Airdropper from '../truffleconf/ERC20Airdropper';
import AccessWhitelist from '../truffleconf/AccessWhitelist';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        provider: null,
        signer: null,
        chain: null,
        contracts: null,
        accounts: null,
        account: null,
        accountBalance: null,
        tokensInAccount: [],
        tokenFees: null,
        lang: 'gb'
    },
    mutations: {
        updateWeb3Objects(state, { provider, signer, chain, contracts, accounts, account, accountBalance }) {
            Vue.set(state, 'provider', provider);
            Vue.set(state, 'signer', signer);
            Vue.set(state, 'chain', chain);
            Vue.set(state, 'contracts', contracts);
            Vue.set(state, 'accounts', accounts);
            Vue.set(state, 'account', account);
            Vue.set(state, 'accountBalance', ethers.utils.formatUnits(accountBalance, 'ether'));
        },
        tokensInAccount(state, tokens) {
            console.log("tokensInAccount", tokens);
            state.tokensInAccount = _.sortBy(_.uniqBy(tokens, 'contract_address'), 'contract_ticker_symbol');
            console.log("tokensInAccount", state.tokensInAccount);
        },
        tokenFees(state, tokenFees){
            state.tokenFees = tokenFees;
        },
        setLang(state, lang) {
            state.lang = lang;
        }
    },
    actions: {
        async bootstrapWeb3({ commit, dispatch }, { provider, signer, chain }) {
            const accounts = await provider.listAccounts();
            const account = accounts && accounts.length ? accounts[0] : null;

            const accountBalance = await provider.getBalance(account);

            const airdropperAddress = utils.getContractAddressFromTruffleConf(ERC20Airdropper, chain.chainId);
            const accessWhitelistAddress = utils.getContractAddressFromTruffleConf(AccessWhitelist, chain.chainId);
            const contracts = {
                ERC20Airdropper: new ethers.Contract(
                    airdropperAddress,
                    ERC20Airdropper.abi,
                    signer,
                ),
                AccessWhitelist: new ethers.Contract(
                    accessWhitelistAddress,
                    AccessWhitelist.abi,
                    signer
                )
            };

            commit('updateWeb3Objects', { provider, signer, chain, contracts, accounts, account, accountBalance });
            dispatch('getTokensInAccount', { account, chain });
            dispatch('getTokenFees');
        },
        async getTokensInAccount({ state, commit }, { account, chain }) {
            let rootPath = `https://api.covalenthq.com/v1/1/address/${account}/balances_v2/?key=ckey_44c545bc64e84d85aea19ed5304`
            // if (chain && chain.chainId === 3) {
            //     rootPath = rootPath = 'https://api.covalenthq.com/v1/3/address/' + account + '/balances_v2/?key=ckey_44c545bc64e84d85aea19ed5304'
            // }
            if (chain && chain.chainId === 42) {
                rootPath = rootPath = 'https://api.covalenthq.com/v1/42/address/' + account + '/balances_v2/?key=ckey_44c545bc64e84d85aea19ed5304'
            }
 
            console.log(`Using ${rootPath}`);
            const { data } = await axios.get(`${rootPath}`, {
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
            if (data && data.data && data.data.items) {
                console.log("DATA FOUND:::")
                let tempData = [];
                for(let obj of data.data.items){
                    if(obj.contract_address == '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'){
                        tempData = tempData.splice(0,0,obj)
                    }else{
                        tempData.push(obj);
                    }
                }
                commit('tokensInAccount', data.data.items);
            }
        },
        async getTokenFees({ state, commit }) {
            const { data } = await axios.get("https://ethgasstation.info/api/ethgasAPI.json?api-key=b9fa089f50eb8b7b0c526654f5de4b75c491adcbb339a02c4ef13e842111", {
                // headers: {
                //     'Access-Control-Allow-Origin': '*'
                // }
            });
            if (data) {
                console.log("DATA Fees FOUND:::",data)
                commit('tokenFees', data);
            }
        },
        changeLang({ commit }, lang) {
            commit('setLang', lang);
        }
    },
    getters: {
        contracts: state => state.contracts,
        account: state => state.account,
        accountBalance: state => state.accountBalance,
        chain: state => state.chain,
        signer: state => state.signer,
        provider: state => state.provider,
        lang: state => state.lang,
    },
    modules: {}
});